import React, { useEffect, useState } from "react";
import { API_URL } from "../../../../../config/config.js";

const ReferralCode = (props) => {
  const { name, placeholder, type, getValuesFn } = props;
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // for validation check
    // console.log("vemail value", props.value)
  }, [props.value]);

  const applyCode = async () => {
    if (value.length < 4) {
      setError(true);

      return;
    }

    try {
      const res = await fetch(
        `${API_URL}/verifyReferralCode?referal_code=${value}`
      );

      const data = await res.json();

      if (data.success) {
        setSuccess(true);
        const data = {
          value: value,
          id: name,
        };

        getValuesFn(data);
      } else {
        setError(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event) => {
    setError(false);
    setValue(event.target.value);
  };

  const removeCode = () =>{
    setValue("");
    setError(false);
    setSuccess(false);
  };

  return (
    <div className="form-input mtop-2">
    
      <div className="recommendation-text">Have a recommendation code?</div>
      <div className="form-referral recommendation-box">
        <div class="outer-block-referal-code">
          <input
            type={type}
            onChange={handleChange}
            name={name}
            placeholder={placeholder}
            value={value}
          />
          {success?(
            <div class="message-block">Success! Code Applied</div>
          ):""}
          {error?(
            <div class="message-block error">Invalid code. Please try again.</div>
          ):""}
        </div>
         
        {success || error ?(
          <div className="referral-btn error" aria-hidden="true" onClick={removeCode}>
              REMOVE
          </div>
        ): (
          <div className="referral-btn " aria-hidden="true" onClick={applyCode}>
              APPLY
          </div>
        )
        }
      </div>
    </div>
  );
};

export default ReferralCode;
