import React from "react";
import Seo from "../../../components/Seo/seo";
import Layout from "../../../components/Layout/layout";
import HeroSection from "../../../components/CSMFormComp/HeroSection/heroSectionPartTime";
import MLPForm from "../../../components/Forms/CSMPartTimeForm";
import NavBar from "../../../components/NavBar/navbar";
//import NavBar from "../../components/NavBar/navbar";
import Overview from "../../../components/CSMPartTimeComp/Overview/overview";
// import Overview from "../../../components/CSMPartTimeComp/Overview/overview";
import OverviewTitle from "../../../components/CSMPartTimeComp/Overview/OverviewTitle";
import Placements from "../../../components/CSMPartTimeComp/Placements/placements";
import Fee from "../../../components/MLPMainComp/Fee copy/index";
import FeeSlider from "../../../components/MLPMainComp/Fee/fee";
import AdmissionProcess from "../../../components/CSMPartTimeComp/AdmissionProcess/admissionProcess";
import Curriculum from "../../../components/CSMPartTimeComp/Curriculum/curriculum";
import Faq from "../../../components/CSMPartTimeComp/FAQS/faq";
import Floater from "../../../components/Floater/FloaterCSMPartTime";
import Tools from "../../../components/CSMPartTimeComp/ToolsCovered";
import Certification from "../../../components/CSMPartTimeComp/Certification";
//import WhatsappWidget from "../../../components/WhatsappWidget";
import AlumniCarousal from "../../../components/CSMPartTimeComp/Placements/AlumniCarousal";
import CourseStructure from "../../../components/CSMPartTimeComp/courseStructure/courseStructure";
import Trainers from "../../../components/CSMPartTimeComp/Trainers/trainers";
import Eligibility from "../../../components/CSMPartTimeComp/Placements/Eligibility";
import CourseTracks from "../../../components/CSMPartTimeComp/Tracks/CourseTracks";

import {
  courseSnipptJSON,
  faqSnipptJSON,
  videoSnipptJSON,
  eventSnipptJSON,
} from "../../../components/Seo/csmSeoData";

import "./styles.scss";
import IvrSection from "../../../components/MLPMainComp/Ivr";


const MarketingLaunchPad = ({location}) => {
  const navBarContent = [
    {
      name: "Placements",
      id: "placements",
    },
    {
      name: "Experts",
      id: "experts",
      mobileHide: false,
      desktopHide: false,
    },
    {
      name: "Admissions",
      id: "admissions",
    },
    {
      name: "Fee",
      id: "fee",
    },

    {
      name: "FAQS",
      id: "faqs",
    },
    {
      name: "Apply Now",
      id: "hero-div",
    },
 
  ];

  return (
    <React.Fragment>
      <Seo
        title="Part-Time Content Writing & Social Media Marketing Course With Placement"
        description="The best part-time content writing & social media marketing course with pay-after-placement. Learn content writing and social media marketing in 22 weeks. Enroll Now!"
        url="https://www.kraftshala.com/marketing-launchpad/part-time-content-writing-and-social-media-marketing-course/"
        courseSnipptJSON={courseSnipptJSON}
        faqSnipptJSON={faqSnipptJSON}
        videoSnipptJSON={videoSnipptJSON}
        eventSnipptJSON={eventSnipptJSON}
      />

      <div className="main-parttime-csm-page">
        <Layout>
          <div className="hero-div" id="hero-div">
            <HeroSection urlParams={location.search} />
            <MLPForm search={location.search} />
          </div>
          <div className="mlp-main-overview bg-size-180">
            <div className="element-center">
              <Floater />
              <OverviewTitle/>
            </div>
            <NavBar
              content={navBarContent}
              applyLink={`/marketing-launchpad/content-and-social-media-copywriting-course/${location.search}`}
            />

            <Overview />
              
         

            <div id="placements" className="section">
              <Placements />
            </div>

            <div id="alumni" className="section">
              <AlumniCarousal />
            </div>

            <div id="course" className="section">
              <CourseStructure urlParams={location.search} />
            </div>

            <div id="curriculum" className="section">
              <Curriculum />
            </div>

            <Tools />
            <Certification />
            <div id="experts" className="section">
              <Trainers />
            </div>
            <Eligibility />

            <div id="admissions" className="section">
              <AdmissionProcess urlParams={location.search} />
            </div>

            <div id="fee" className="section">
              <Fee />
              <FeeSlider />
            </div>
            
            <CourseTracks />

            <div id="ivr" className="section">
              <IvrSection />
            </div>

            <div id="faqs" className="section">
              <Faq />
            </div>
            <div id="apply now" className="section" urlParams={location.search}>
            
            </div>
          </div>    
        </Layout>
      </div>
      
      {/* <WhatsappWidget link="https://wa.me/message/HHNAOZDIMB4ZE1" /> */}
    </React.Fragment>
  );
};

export default MarketingLaunchPad;
